'use client'

import {MoreButton} from "@/components/buttons/MoreButton.tsx";
import {Attractions} from "@/utils/directus.types.ts";
import {useLocale, useTranslations} from "next-intl";
import React, {ReactNode, useState} from "react";
import {entityTranslator, useEntityTranslator} from "@/utils/i18n-helpers.ts";
import {attractionLink} from "@/utils/links.ts";
import {takeCities} from "@/utils/directus.ts";
import {ButtonData, SectionFilterButtons} from "@/components/contentSections/SectionFilterButtons.tsx";
import {FlexSubSection} from "@/components/contentSections/FlexSubSection.tsx";
import {FlexSection} from "@/components/contentSections/FlexSection.tsx";
import {FlexHeaderSection} from "@/components/contentSections/FlexHeaderSection.tsx";
import {formatCurrency} from "@/i18n.tsx";
import style from "./AttractionListSection.module.sass";
import sliderStyle from "./AttractionSliderSection.module.sass";
import unfortunate from "@/styles/unfortunate.module.sass";
import FallbackImage from "@/components/images/FallbackImage.tsx";
import LocaleLink from "@/components/navigation/LocaleLink.tsx";

function AttractionItem({attraction, loading}: { attraction: Attractions, loading?: "lazy" | "eager" }) {
  const t = useTranslations()
  const et = useEntityTranslator()
  return <article>
    <LocaleLink href={attractionLink(attraction)} title={et(attraction, 'title')} className={unfortunate['cardItem']}>
      <div className={style['listItem']}>
        <FallbackImage loading={loading} className={unfortunate['poster']}
                       galleryImage={attraction.preview_image} tiqetsImage={attraction.tiqets_images?.[0]}
                       width={271} height={271}
                       fallbackAlt={et(attraction, 'title')} fallbackTitle={et(attraction, 'title')}/>
      </div>
      <div className={sliderStyle['text']}>
        <div className={sliderStyle['title']}>
          {et(attraction, 'title')}
        </div>
        {!!attraction.min_price && !!+attraction.min_price && <div className={sliderStyle['price']}>
          {t('sections.attractions.priceFrom', {price: +attraction.min_price}, formatCurrency('EUR'))}
        </div>}
      </div>
    </LocaleLink>
  </article>
}

function lexicalSort(a: string, b: string) {
  return +(a < b) - +(a > b)
}

export const AttractionListSection = ({attractions, filter, multiplier, header, title, className, loading}: {
  attractions: Attractions[]
  filter?: boolean
  multiplier?: number
  header?: ReactNode
  title?: string
  className?: string
  loading?: "eager" | "lazy"
}) => {
  const showMultiplier = multiplier || 8;
  const locale = useLocale()
  const t = useTranslations()
  const et = entityTranslator(locale)
  const [showMore, setShowMore] = useState(0)
  const onToggle = () => {
    setShowMore(showMore + showMultiplier)
  };
  const filterOptions = (filter ? takeCities(attractions) : []).sort((a, b) => lexicalSort(b.slug, a.slug))
    .map(c => ({
      identity: c.slug,
      label: et(c, 'title'),
    }));
  const [filteredBy, setFilteredBy] = useState<ButtonData>(filterOptions[0]);
  const onFilter = (filterBy: ButtonData) => {
    setShowMore(0)
    setFilteredBy(filterBy)
  }
  const filteredItems = filter ? attractions.filter(a => a.city.slug === filteredBy.identity) : attractions;
  return <FlexSection className={className}>
    {header ? header : <FlexHeaderSection>
      {title || t('sections.attractions.header')}
    </FlexHeaderSection>}
    {(filter ?? false) && <SectionFilterButtons options={filterOptions} selected={filteredBy} onSelect={onFilter}/>}
    <FlexSubSection>
      <div className={style['attractionListGrid']}>
        {filteredItems.slice(0, showMultiplier + showMore)
          .map((attraction, i) => <AttractionItem attraction={attraction} key={attraction.id}
                                                  loading={i > 3 ? "lazy" : (loading || "eager")}/>)}
      </div>
      {(filteredItems.length > showMultiplier + showMore) && <MoreButton opened={false} onClick={onToggle}/>}
    </FlexSubSection>
  </FlexSection>
}
